button {
  font-family: "Anton";
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2.5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffffff;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  padding: 1.7vw 3.5vw;
  background: #FF6F69;
  color: white;
  border: none;
  &:disabled{
    background: #ccc;
  }
}

.home {
  .hero-wrapper {
    background-image: url("https://res.cloudinary.com/t-brainstorm/image/upload/v1642687928/unnamed_d5b361952c.jpg");
    background-size: cover;
    padding-top: 9vw;
    padding-bottom: 9vw;
    position: relative;

    .container {
      position: relative;
    }

    h1 {
      font-family: "Anton";
      color: #000000;
      font-size: 2.9167vw;
      text-align: center;
      width: 46.6146vw;
      margin: auto auto 3vw;
    }

    h3 {
      font-family: "Poppins";
      font-weight: 400;
      display: table-cell;
      vertical-align: top;
      color: #000000;
      font-size: 1.1458vw;
      width: 30.7500vw;
      font-weight: 500;
      margin: auto auto 3vw;
      display: block;
      text-align: center;
    }

    button {
      display: block;
      margin: auto;
    }

    .hero-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: rgba(255, 255, 255, 0.3);
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vw 0;
  }

  .slider-section {
    background-color: #f9f6f1ff;
    position: relative;

    .slider-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .text-section {
      width: 50%;
      padding: 9vw 10vw 9vw 0;

      h2 {
        font-family: "Anton";
        font-weight: 400;
        display: table-cell;
        vertical-align: top;
        color: #000000;
        font-size: 2.5vw;
        display: block;
        margin-bottom: 2.5vw;
      }

      p {
        font-family: "Poppins";
        font-size: 1.1458vw;
        margin-bottom: 2.5vw;
        padding-right: 4vw;
      }
    }

    .slider-part {
      width: 50%;
      background-image: url("https://s3.eu-central-1.wasabisys.com/balkan-apartments/ttm/1.jpeg");
      position: absolute;
      right: 0;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;

      .slider {
        width: 25.5208vw;
        height: 17.3438vw;
        background: #FF6F69;
        position: relative;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);

        .slick-slider {
          height: 100%;
        }

        .slick-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1vw;
          color: white;
          z-index: 1;
          left: auto;
          height: 2vw;
          width: 1vw;
        }

        .slick-prev {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 1vw;
          color: white;
          z-index: 1;
          height: 2vw;
          width: 1vw;
        }

        .home-slide {
          padding: 2.8vw 0;
          position: relative;

          img {
            width: 3.4375vw;
            height: 3.4896vw;
            height: 3.4896vw;
            display: block;
            margin: auto;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: pop 1.2s infinite;
          }

          p {
            font-family: "Poppins";
            font-size: 1.0458vw;
            text-align: center;
            padding: 6.2896vw 2.7vw 0;
            margin-bottom: 0;
            color: white;
          }
        }
      }
    }
  }

  .lighthouse-section {
    background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/pattern.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2.5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.9167vw;
      text-align: center;
    }
  }

  .lighthouse-wrapper {
    display: flex;
    flex-wrap: wrap;

    .lh-text-section {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 3vw 0 2vw;
      position: relative;

      p {
        font-family: "Poppins";
        font-weight: 500;
        vertical-align: top;
        color: #000000;
        font-size: 1.0417vw;
        margin-bottom: 1.5vw;

        img {
          position: absolute;
          left: 0;
          width: 1.5vw;
          animation: rpop 1.2s infinite;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h4 {
        font-family: "Poppins";
        font-weight: 700;
        vertical-align: top;
        color: #000000;
        font-size: 1.1979vw;
        margin-bottom: 1.5vw;
      }
    }

    .lighthouse-image {
      width: 35%;
      flex-shrink: 0;
      display: block;
      margin: auto;
      animation: fade 5s infinite;
    }
  }

  .guess-what-section {
    background: #f9f6f1ff;
    padding: 2.5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      vertical-align: top;
      color: #000000;
      font-size: 2.3958vw;
      text-align: center;
      margin-bottom: 1.2500vw;
    }

    h4 {
      font-family: "Poppins";
      font-weight: 400;
      vertical-align: top;
      color: #000000;
      font-size: 1.2500vw;
      text-align: center;
      margin-bottom: 1.2500vw;

      &.orange {
        color: #FF6F69;
      }
    }

    p {
      font-family: "Poppins";
      font-weight: 500;
      vertical-align: top;
      color: #000000;
      font-size: 24px;
      text-align: center;
    }

    img {
      display: block;
      margin: auto auto 1.2500vw;
    }
  }

  .hi-section {
    background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/pattern-2.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5vw 0;

    .hi-header-section {
      display: flex;
      margin-bottom: 2.5vw;
      align-items: center;

      img {
        width: 23.6167vw;
        margin-right: 5vw;
      }

      h2 {
        font-family: "Anton";
        font-weight: 400;
        color: #000000;
        font-size: 2.2917vw;
      }
    }

    h3 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 1.2500vw;
    }

    p {
      font-family: "Poppins";
      font-weight: 500;
      vertical-align: top;
      color: #000000;
      font-size: 24px;
      text-align: justify;
      margin-bottom: 1.25vw;
      &:nth-child(3){
        margin-bottom: 2.5vw;
      }
    }

    .hi-button {
      display: flex;
      align-items: center;

      button {
        margin-left: 2.5vw;
      }
    }
  }

  .direction-section {
    padding: 5vw 0;
    background: #f9f6f1ff;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.9167vw;
      text-align: center;
      margin-bottom: 5vw;
    }

    .direction-item {
      background: white;
      padding: 2.5vw;
      min-height: 32.2vw;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img.bird {
        width: 40px;
        display: block;
        margin: 0 auto;
        animation: pop 1.5s infinite;
        position: relative;
        left: 20px;
      }

      h3 {
        font-family: "Anton";
        font-weight: 400;
        color: #000000;
        font-size: 1.5625vw;
        text-align: center;
        margin-bottom: 2.5vw;
      }

      p,
      ul li {
        font-family: "Poppins";
        font-weight: 400;
        color: #000000;
        font-size: 21px;
        line-height: 1.35;
        text-align: center;
      }

      ul {
        list-style: none;
        padding: 0;
        // min-height: 11vw;
      }

      ul li {
        position: relative;
        text-align: center;
        // margin-bottom: 1.31vw;

        span {
          padding-left: 1.5vw;
        }

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .last-check {
          top: 25%;
        }
      }

      button {
        padding: 0.7vw 3.5vw;
        display: block;
        margin: auto auto;
        position: relative;
        bottom: 1vw;
      }
    }
  }

  .citation-section.slider-section {
    .slider-part {
      background-image: url("https://s3.eu-central-1.wasabisys.com/balkan-apartments/ttm/2.jpeg");
    }

    .text-section {
      padding: 5vw 10vw 5vw 0;
    }
  }

  .route-section {
    background: white;
    padding: 5vw 0 7.5vw;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.3958vw;
      min-height: 2.5958vw;
      text-align: center;
      margin-bottom: 5vw;
      animation: move-text 0.75s forwards;
      opacity: 0;
    }

    h3 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 1.7708vw;
      margin-bottom: 1.25vw;
    }

    p {
      font-family: "Poppins";
      font-weight: 400;
      color: #000000;
      font-size: 21px;
      margin-bottom: 0;
    }

    .route-header {
      background: #F9F6F1;
      padding: 1.25vw 2.5vw;
    }

    .route-slide {
      background: #FF6F68;
      padding: 2.5vw;

      p {
        font-family: "Poppins";
        font-weight: 400;
        color: #FFFFFF;
        font-size: 21px;
      }

      span {
        font-family: "Poppins";
        font-weight: 700;
        color: #FFFFFF;
        font-size: 21px;
      }
    }

    .slick-dots {
      bottom: -2.5vw;
    }

    .slick-dots li button:before {
      font-size: 1vw;
    }
  }

  .thing-section {
    padding: 5vw 0;
    background-color: #F9F6F1;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.5vw;
      display: block;
      margin-bottom: 2.5vw;

      &.or-h2 {
        font-size: 1.75vw;
        text-align: center;
      }
    }

    h3 {
      font-family: "Poppins";
      font-weight: 400;
      color: #FF6F69;
      font-size: 1.2500vw;
      margin-bottom: 2.5vw;
      text-decoration: line-through;
    }

    button {
      padding: 0.7vw 3.5vw;
      display: block;
      margin: auto;
    }
  }
}
@keyframes pop {
  0% {
    transform: translate(-50%, -50%) scale(1.25, 0.75);
  }

  50% {
    transform: translate(-50%, -150%) scale(1, 1);
  }

  55% {
    transform: translate(-50%, -150%) rotate(15deg);
  }

  60% {
    transform: translate(-50%, -150%) rotate(-15deg);
  }

  65% {
    transform: translate(-50%, -150%) rotate(15deg);
  }

  70% {
    transform: translate(-50%, -150%) rotate(-15deg);
  }

  100% {
    transform: translate(-50%, -50%) scale(1.25, 0.75);
  }
}
@keyframes rpop {
  0% {
    transform: scale(1.25, 0.75);
  }

  50% {
    transform: scale(1, 1);
  }

  55% {
    transform: rotate(15deg);
  }

  60% {
    transform: rotate(-15deg);
  }

  65% {
    transform: rotate(15deg);
  }

  70% {
    transform: rotate(-15deg);
  }

  100% {
    transform: scale(1.25, 0.75);
  }
}
@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }

  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}
@media (max-width: 991px) {
  .custom-navigation {
    height: 18.7500vw;
  }

  .custom-navigation .navbar-brand img {
    width: 24vw;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }

  .navbar-light .navbar-toggler {
    color: #FF6F69;
    outline: none;
    box-shadow: none;
  }

  .navbar-light .navbar-toggler-icon {
    color: #FF6F69;
    fill: #FF6F69;
  }

  .home .hero-wrapper {
    padding: 20vw 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .home .hero-wrapper h1 {
    font-size: 6.2500vw;
    width: 100%;
  }

  .home .hero-wrapper h3 {
    width: 100%;
    // width: 96.7391vw;
    line-height: 5.4348vw;
    text-align: center;
    font-size: 1rem;
  }

  .home .logo-wrapper {
    width: 100%;
    overflow: scroll;

    img {
      padding: 3vw;
      object-fit: contain;

      &:last-child {
        padding-right: 5vw;
      }

      &:first-child {
        padding-left: 5vw;
      }
    }
  }

  button {
    line-height: 1;
    font-size: 20px;
  }

  .home .slider-section .text-section {
    padding: 12vw 0;
  }

  .home .slider-section .slider-part,
  .home .slider-section .text-section {
    width: 100%;
  }

  .home .slider-section .slider-part {
    position: relative;
    padding: 9vw 0;

    .slider {
      top: 0;
      transform: none;
      display: block;
      margin: auto;
      width: 71.6033vw;
      height: auto;
    }
  }

  .home .slider-section .slider-part .slider .home-slide {
    padding: 15vw 5vw 5vw;
  }

  .home .slider-section .slider-part .slider .home-slide p {
    font-size: 1rem;
  }

  .home .slider-section .text-section h2 {
    font-size: 6.2500vw;
  }

  .home .slider-section .text-section p {
    font-size: 1rem;
  }

  .home .slider-section .slider-part .slider .slick-next,
  .home .slider-section .slider-part .slider .slick-prev {
    height: 5vw;
    width: 4vw;
  }

  .home .slider-section .slider-part .slider .home-slide img {
    width: 8.4375vw;
    height: 8.4896vw;
    top: 30%;
  }

  .home .lighthouse-section {
    padding: 12vw 0 25vw;
    position: relative;
  }

  .home .lighthouse-wrapper {
    flex-direction: column;
  }

  .home .lighthouse-wrapper .lh-text-section {
    width: 100%;
    padding: 0 20vw 0 8.5vw;
  }

  .home .lighthouse-section h2 {
    font-size: 6.2500vw;
    margin-bottom: 12vw;
  }

  .home .lighthouse-wrapper .lh-text-section h4,
  .home .lighthouse-wrapper .lh-text-section p {
    font-size: 1rem;
  }

  .home .lighthouse-wrapper .lh-text-section p img {
    width: 6.5vw;
  }

  .home .lighthouse-wrapper .lighthouse-image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .home .guess-what-section {
    padding: 12vw 0;
  }

  .home .guess-what-section h2 {
    font-size: 6.25vw;
    margin-bottom: 3vw;
  }

  .home .guess-what-section h4,
  .home .guess-what-section p {
    font-size: 1rem;
    margin-bottom: 3vw;
  }

  .home .hi-section .hi-header-section {
    flex-direction: column;

    img {
      width: 50vw;
      margin-bottom: 6vw;
    }

    h2 {
      font-size: 6.25vw;
      margin-bottom: 3vw;
    }
  }

  .hi-header-section-text {
    width: 100%;
  }

  .home .hi-section h3,
  .home .hi-section p {
    font-size: 1rem;
    text-align: left;
    margin-bottom: 3vw;
  }

  .home .hi-section h3.hi-bottom-h3 {
    margin-bottom: 0;
  }

  .home .direction-section {
    padding: 12vw 0;
  }

  .home .direction-section h2 {
    font-size: 6.25vw;
    margin-bottom: 12vw;
  }

  .home .direction-section .direction-item {
    padding: 6vw;
    padding-bottom: 15vw;
  }

  .home .direction-section .direction-item img.bird {
    width: 9.8vw;
    display: block;
    animation: pop 1.5s infinite;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin: 0;
  }

  .home .direction-section .direction-item h3 {
    font-size: 6.25vw;
    margin-bottom: 3vw;
  }

  .home .direction-section .direction-item p,
  .home .direction-section .direction-item ul li {
    font-size: 1rem;
  }

  .home .direction-section .direction-item ul li span {
    padding-left: 5.5vw;
  }

  .home .direction-section .direction-item {
    margin-bottom: 12vw;
  }

  .home .citation-section {
    margin-top: -12vw;

    &.slider-section {
      .slider-part {
        padding: 30vw 0;
      }
    }
  }

  .home .route-section {
    padding: 12vw 0;
  }

  .home .route-section h2 {
    font-size: 6.25vw;
  }

  .home .route-section .route-header {
    padding: 6vw 6vw 3vw;
  }

  .home .route-section h3 {
    font-size: 6.25vw;
    margin-bottom: 3rem;
  }

  .home .route-section p {
    font-size: 1rem;

    br {
      &:last-child {
        display: none;
      }
    }
  }

  .home .route-slider button.slick-arrow {
    display: none !important;
  }

  .home .route-section .route-slide p,
  .home .route-section .route-slide span {
    font-size: 1rem;
  }

  .home .route-section .slick-dots {
    bottom: -7.7vw;
  }

  .home .route-section .slick-dots li button:before {
    font-size: 3vw;
  }

  .home .thing-section {
    padding: 12vw 0;
  }

  .home .thing-section h2 {
    font-size: 6.25vw;
    margin-bottom: 6vw;
  }

  .home .thing-section h3 {
    font-size: 1rem;
    line-height: 6.5217vw;
    text-decoration: line-through;
    margin-bottom: 6vw;
  }

  .home .thing-section h2.or-h2 {
    font-size: 6.25vw;
    margin-bottom: 6vw;
  }
}
