.custom-navigation {
  height: 80px;
  background: white;

  .navbar-brand {
    width: 7.8125vw;

    img {
      width: 100%;
    }
  }
  li {
    &.subscribe-nav-item {
        a {
          border: 2px solid #FF6F69;
          color: #FF6F69;
          margin-left: 1.5vw;
        }
    }
    a {
      font-size: 20px;
      font-family: "Anton";
      color: #373737;
      padding: 0.3vw 1.5vw;
      text-decoration: none;
      text-transform: uppercase;

      &.acitve {
        color: #FF6F69;
      }
    }
  }

  #navbarSupportedContent {
    justify-content: flex-end;
  }
}

.custom-navigation li a.active {
  color: #FF6F69;
}

@media (max-width: 991px) {
  .custom-navigation {
    #navbarSupportedContent {
      position: absolute;
      left: 0;
      top: 0;
      top: 18.75vw;
      height: calc(100vh - 18.75vw);
      background: white;
      z-index: -1;

      &.show {
        display: flex;
      }
      align-items: center;
      justify-content: center;

      ul {
        width: 62%;

        li {
          a {
            font-family: "Anton";
            font-weight: 400;
            color: #373737;
            font-size: 24px;
            line-height: 20vw;
            text-decoration: none;
            text-align: center;
            width: 100%;
            display: block;
            padding: 0 30px;
          }
        }
      }
    }
  }
}
