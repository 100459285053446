.login-container {
  background: #F9F6F1;
  padding: 77px 0;

  .login-form {
    max-width: 434px;
    display: block;
    margin: auto;

    h1 {
      text-align: center;
      font: normal normal normal 25px/24px Anton;
      letter-spacing: 0px;
      color: #373737;
      margin-bottom: 35px;
    }

    input[type=password],
    input[type=text] {
      background: none;
      outline: none;
      box-shadow: none;
      border: 1px solid #445C60;
      display: block;
      height: 63px;
      width: 100%;
      margin-bottom: 20px;
      padding: 20px 32px;
      font: normal normal normal 16px/24px Poppins;
      letter-spacing: 0px;
      color: #373737;
    }

    .login-actions-wrapper {
      margin-bottom: 35px;
    }

    .login-actions-wrapper,
    .login-actions-wrapper a {
      display: flex;
      justify-content: space-between;
      font: normal normal normal 16px/24px Poppins;
      letter-spacing: 0px;
      color: #373737;
    }

    button {
      height: 63px;
      width: 100%;
      margin-bottom: 20px;
    }

    .register-write {
      margin-bottom: 35px;
    }
    
    .register-write,
    .register-write a {
      display: flex;
      justify-content: space-between;
      font: normal normal normal 16px/24px Poppins;
      letter-spacing: 0px;
      color: #373737;
    }
    .input-error{
      color:red;
      position: relative;
      top:-20px;
    }
  }
}
