.contact {
  .gray-section {
    background-color: #445C60;
    padding: 5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #FFFFFF;
      font-size: 2.3958vw;
      text-align: center;
    }

    h3 {
      font-family: "Anton";
      font-weight: 400;
      color: #84A1A5;
      font-size: 1.8750vw;
      text-align: center;
    }
  }

  .contact-form {
    padding: 5vw 0;
    background-color: #f9f6f1ff;

    h1 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 1.8750vw;
      margin-bottom: 3vw;
    }

    p {
      font-family: "Poppins";
      font-weight: 400;
      color: #000000;
      font-size: 1.0417vw;
      margin-bottom: 3vw;
    }

    button {
      padding: 0.7vw 3.5vw;
    }

    .row {
      justify-content: center;
    }

    form {
      input {
        display: block;
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        width: 100%;
        border-bottom: 1px solid gray;
        color: black;
        text-align: center;
        height: 2.5vw;
        margin-bottom: 1.42vw;

        &::placeholder {
          font-family: "Poppins";
          font-weight: 400;
          color: #000000;
          font-size: 0.9375vw;
          line-height: 1.2;
        }
      }

      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 991px) {
  .contact .gray-section h2 {
    font-size: 5.5vw;
  }

  .contact .gray-section h3 {
    font-size: 1rem;
  }

  .contact .contact-form {
    padding: 12vw 0;
  }

  .contact .contact-form h1 {
    font-size: 6.25vw;
  }

  .contact .contact-form p {
    font-size: 1rem;
  }

  .contact .contact-form form input {
    font-size: 1rem;
    height: 1.3rem;
    text-align: left;
    margin-bottom: 6vw;

    &::placeholder {
      font-size: 1rem;
    }
  }

  .contact .contact-form form {
    margin: 6vw 0;
  }

  .contact .contact-form form button {
    margin-top: 6vw;
  }
}
