.account{
    background-color: #f9f6f1;
    padding-top: 80px;
    padding-bottom: 80px;
    h1 {
    text-align: left;
    font: normal normal normal 25px/24px Anton;
    letter-spacing: 0px;
    color: #373737;
    margin-bottom: 35px;
  }
  .account-info{
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    font-size: 14px;
    .account-info-item{
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        flex-direction: row;
        svg{
            font-size: 20px;
            width: 50px;
        }
        input{
            background: transparent;
            border: none;
            border-bottom: 1px solid #000;
            width: 100%;
        }
    }
  }
  .account-info-wrapper{
      display: flex;
      flex-direction: column;
  }
  .account-info-header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      button{
          color:#FD6F6A;
          border:1px solid #FD6F6A;
          background: transparent;
          padding: 7px 23px;
          font-size: 16px;
          font-weight: normal;
          font-family: "Poppins";
          height: 37px;
          text-transform: capitalize;
      }
  }
  .edit-buttons{
      display: flex;
      flex-direction: row;
      button{
          margin-left: 10px;
      }
  }
  .account-content{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .account-type-header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      h1{
          font-size: 22px;
      }
      .type-header{
          background: #FD6F6A;
          font-size: 14px;
          color: #fff;
          padding: 14px;
          margin-left: 10px;
      }
  }
  .video-wrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: auto;
    max-height: 600px;
    &::-webkit-scrollbar {
        width: 3px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #FD6F6A;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
  }
  .video-item {
    background: #DADBDC;
    cursor: pointer;
    width: 100%;
    margin-right: 1%;
    margin-bottom: 1%;
    display: flex;
    img{
      width: 100%;
    }
  }
  .account-videos{
      padding-left: 15px;
  }
  .edit-sub{
    color: #FD6F6A;
    border: 1px solid #FD6F6A;
    background: transparent;
    padding: 7px 23px;
    font-size: 16px;
    font-weight: normal;
    font-family: "Poppins";
    height: 37px;
    text-transform: capitalize;
  }
  @media (max-width: 991px) {
      .account-content{
          margin-top: 50px;
          align-items: flex-start;
      }
  }
}
