.about {
  .about-header {
    background: #F9F6F1;
    background-image: url('https://s3.eu-central-1.wasabisys.com/balkan-apartments/ttm/ttm-4bout.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .about-header-wrapper {
      display: flex;
      align-items: center;
    }

    h1 {
      width: 50%;
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 1.8750vw;
      padding-right: 5vw;
    }

    .about-header-image {
      width: 50%;
      padding: 5vw 5vw 0;

      img {
        width: 100%;
        visibility: hidden;
      }
    }
  }

  .gray-section {
    background-color: #445C60;
    padding: 5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #FFFFFF;
      font-size: 2.3958vw;
      text-align: center;
    }

    h3 {
      font-family: "Anton";
      font-weight: 400;
      color: #84A1A5;
      font-size: 1.8750vw;
      text-align: center;
    }
  }

  .years-wrapper {
    padding: 5vw 0;

    .about-item {
      display: flex;
      justify-content: center;
      margin-bottom: 5vw;

      .year-wrapper {
        background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/bg-icon.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 6.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 2vw;
        height: 8.334vw;
        position: relative;

        span {
          display: block;
          font-family: "Poppins";
          font-weight: 900;
          text-align: center;
          color: #FFFFFF;
          font-size: 1.6146vw;
          margin-bottom: 3vw;
        }

        img {
          animation: pop 1.2s infinite;
          position: absolute;
          top: 85%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .text-wrapper {
        width: 60%;

        p.title {
          font-family: "Poppins";
          font-weight: 700;
          color: #000000;
          font-size: 0.9375vw;
        }

        p.content {
          font-family: "Poppins";
          font-weight: 400;
          color: #000000;
          font-size: 0.9375vw;
          line-height: 2;
        }
      }
    }
  }

  .who-section {
    padding: 5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.8646vw;
      text-align: center;
    }

    .about-slide {
      .span2 {
        font-family: "Poppins";
        font-weight: 700;
        color: #000000;
        font-size: 1.4375vw;
        margin-bottom: 1.1vw;
        display: block;
        text-align: center;
      }

      .span3,
      .last-question {
        font-family: "Poppins";
        font-weight: 400;
        color: #000000;
        font-size: 1.2375vw;
        text-align: center;
        display: block;
      }
    }

    .counter {
      text-align: center;
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 1.1458vw;
    }
  }

  .team-section {
    padding: 5vw 0;
    background-color: #F9F6F1;

    .justify-center {
      justify-content: center;
    }

    .team-item {
      padding: 0 1vw;
    }

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.8646vw;
      text-align: center;
      margin-bottom: 5vw;
    }

    img {
      width: 7.8571vw;
      height: 7.8571vw;
      object-fit: cover;
      display: block;
      margin: auto;
      margin-bottom: 2.5vw;
      border-radius: 50%;
    }

    h3 {
      font-family: "Poppins";
      font-weight: 700;
      color: #000000;
      font-size: 1.1979vw;
      text-align: center;
    }

    p {
      font-family: "Poppins";
      font-weight: 400;
      color: #000000;
      font-size: 0.9375vw;
      line-height: 1.3750vw;
      text-align: center;
    }
  }

  .slider-section {
    background-color: white;
    position: relative;

    .slider-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .text-section {
      width: 50%;
      padding: 9vw 10vw 9vw 0;

      h2 {
        font-family: "Anton";
        font-weight: 400;
        display: table-cell;
        vertical-align: top;
        color: #FF6F69;
        font-size: 2.5vw;
        display: block;
        margin-bottom: 2.5vw;
      }

      p {
        font-family: "Poppins";
        font-size: 1.1458vw;
        margin-bottom: 2.5vw;
      }
    }

    .slider-part {
      width: 50%;
      background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/baby-2.png");
      position: absolute;
      right: 0;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;

      .slider {
        width: 25.5208vw;
        height: 17.3438vw;
        background: #FF6F69;
        position: relative;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);

        .slick-slider {
          height: 100%;
        }

        .slick-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1vw;
          color: white;
          z-index: 1;
          left: auto;
          height: 2vw;
          width: 1vw;
        }

        .slick-prev {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 1vw;
          color: white;
          z-index: 1;
          height: 2vw;
          width: 1vw;
        }

        .home-slide {
          padding: 2.8vw 0;

          img {
            width: 3.4375vw;
            height: 3.4896vw;
            display: block;
            margin: auto;
          }

          p {
            font-family: "Poppins";
            font-size: 1.0458vw;
            text-align: center;
            padding: 2.7vw 2.7vw 0;
            margin-bottom: 0;
            color: white;
          }
        }
      }
    }

    .slick-dots {
      bottom: -2.5vw;
      text-align: left;
      margin-left: -5px;
    }

    .slick-dots li button:before {
      font-size: 1vw;
    }
  }
}

@media (max-width: 991px) {
  .about .about-header .about-header-wrapper {
    flex-direction: column;
    padding-top: 12vw;
  }

  .about .about-header h1 {
    font-size: 6.25vw;
    width: 100%;
  }

  .about .about-header .about-header-image {
    width: 70%;
    padding: 6vw 6vw 0;
  }

  .about .gray-section h2 {
    font-size: 5.5vw;
  }

  .about .gray-section h3 {
    font-size: 1rem;
  }

  .about .years-wrapper .about-item .text-wrapper p.content,
  .about .years-wrapper .about-item .year-wrapper span {
    font-size: 1rem;
  }

  .about .years-wrapper .about-item .year-wrapper {
    width: 11.5%;
    height: 22.334vw;
  }

  .about .years-wrapper .about-item .text-wrapper {
    width: 88.5%;
  }

  .about .years-wrapper .about-item .year-wrapper img {
    width: 35%;
    top: 75%;
  }

  .about .who-section,
  .about .team-section {
    padding: 12vw 0;
  }

  .about .team-section h2,
  .about .who-section h2 {
    font-size: 6.25vw;
    margin-bottom: 6vw;
  }

  .about .who-section .about-slide .span1,
  .about .who-section .about-slide .span2,
  .about .who-section .about-slide .span3,
  .about .who-section .counter {
    font-size: 1rem;
  }

  .about .team-section img {
    width: 27.8571vw;
    height: 27.8571vw;
  }

  .about .team-section h3 {
    font-size: 1rem;
    margin-bottom: 3vw;
  }

  .about .team-section p {
    font-size: 1rem;
    line-height: 5.4348vw;
    margin-bottom: 3vw;
  }

  .about .slider-section .text-section {
    padding: 12vw 0;
  }

  .about .slider-section .slider-part,
  .about .slider-section .text-section {
    width: 100%;
  }

  .about .slider-section .slider-part {
    position: relative;
    padding: 9vw 0;

    .slider {
      top: 0;
      transform: none;
      display: block;
      margin: auto;
      width: 71.6033vw;
      height: auto;
    }
  }

  .about .slider-section .slider-part .slider .home-slide {
    padding: 15vw 5vw 5vw;
  }

  .about .slider-section .slider-part .slider .home-slide p {
    font-size: 1rem;
  }

  .about .slider-section .text-section h2 {
    font-size: 6.2500vw;
  }

  .about .slider-section .text-section p {
    font-size: 1rem;
  }

  .about .slider-section .slider-part .slider .slick-next,
  .about .slider-section .slider-part .slider .slick-prev {
    height: 5vw;
    width: 4vw;
  }

  .about .slider-section .slider-part .slider .home-slide img {
    width: 8.4375vw;
    height: 8.4896vw;
    top: 30%;
  }

  .citation-section.slider-section .slider-part {
    padding: 20vw 0;
  }
}
