.subscribe-hero {
  padding: 7.5vw 0;
  background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/pattern-3.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-family: "Poppins";
    font-weight: 400;
    color: #000000;
    font-size: 1.3542vw;
    text-align: center;
    margin-bottom: 2.5vw;
  }

  h1 {
    font-family: "Anton";
    font-weight: 400;
    color: #000000;
    font-size: 2.9167vw;
    text-align: center;
  }
}

.subscribe {
  padding: 5vw 0;
  background-image: url('https://s3.eu-central-1.wasabisys.com/tarik/bird-pattern.svg');
  background-color: #F9F6F1;
  background-size: 100%;
  background-repeat: no-repeat;

  .s-item {
    padding-top: 2.4vw;
    .subscribe-item {
      background: white;
      padding: 2.5vw 1.25vw;
      border-radius: 3vw;
      transition: 0.2s all;
      img {
        display: block;
        margin: auto;
        width: 4.0832vw;
        margin-bottom: 1vw;
      }

      button {
        width: 100%;
        padding: 0.7vw 3.5vw;
        line-height: 2;
        font-size: 20px;
      }

      h2 {
        font-family: Anton, sans-serif;
        font-size: 2.1354vw;
        text-align: center;
        margin-bottom: 2.5vw;
      }

      h3 {
        color: #ff6f69;
        font-family: Anton, sans-serif;
        font-size: 2.1354vw;
        text-align: center;
      }

      p {
        line-height: 1.6667vw;
        text-align: center;
        font-family: "Poppins";
        font-weight: 400;
        color: #000000;
        font-size: 0.9375vw;
        text-align: center;
        margin-bottom: 2.5vw;
      }
    }

    &.active {
      padding-top: 0;
      .subscribe-item {
        img {
          width: 4.5019vw;
        }

        h2,
        h3 {
          font-size: 2.3438vw;
        }
        p {
          font-size: 1.0375vw;
          margin-bottom: 3.5vw;
        }
      }
    }
  }

  .swapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5vw 0;

    .swapper-item {
      width: 1.5vw;
      height: 1.5vw;
      border-radius: 50%;
      background: black;
      margin: 0.25vw;

      &.active {
        width: 4.5vw;
        border-radius: 3vw;
        background: #FF6F69;
      }
    }
  }
}

@media (max-width: 991px) {
  .subscribe {
    padding: 12vw;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .subscribe .s-item .subscribe-item {
    padding: 6vw;
    margin-bottom: 12vw;
  }

  .subscribe .s-item .subscribe-item img {
    width: 13.0832vw;
    transform: translate(-50%, -50%);
    margin: 0;
    left: 50%;
    animation: pop 1.2s infinite;
    position: relative;
  }

  .subscribe .s-item .subscribe-item h2,
  .subscribe .s-item .subscribe-item h3 {
    font-size: 6.25vw;
    margin: 6vw 0;

    br {
      display: none;
    }
  }

  .subscribe .s-item .subscribe-item p {
    font-size: 1rem;
    line-height: 1.5;
  }
  .subscribe .s-item.active .subscribe-item {
    padding: 6vw;
    margin-bottom: 12vw;
  }

  .subscribe .s-item.active .subscribe-item img {
    width: 13.0832vw;
    transform: translate(-50%, -50%);
    margin: 0;
    left: 50%;
    animation: pop 1.2s infinite;
    position: relative;
  }

  .subscribe .s-item.active .subscribe-item h2,
  .subscribe .s-item.active .subscribe-item h3 {
    font-size: 6.25vw;
    margin: 6vw 0;

    br {
      display: none;
    }
  }

  .subscribe .s-item.active .subscribe-item p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .subscribe .swapper {
    display: none;
  }
}

@media (max-width: 991px) {
  .subscribe-hero {
    padding: 12vw 0;
    background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/pattern-3.jpeg");
  }

  .subscribe-hero .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .subscribe-hero h2 {
    font-size: 1rem;
    margin-bottom: 0;
    padding: 0 6vw;
  }

  .subscribe-hero h1 {
    font-size: 6.25vw;
    margin-bottom: 12vw;
  }

}
