.services {
  .services-hero {
    padding: 7.5vw 0;
    background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/pattern-3.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h2 {
      font-family: "Poppins";
      font-weight: 400;
      color: #000000;
      font-size: 1.3542vw;
      text-align: center;
      margin-bottom: 2.5vw;
    }

    h1 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.9167vw;
      text-align: center;
    }
  }

  .tried-section {
    padding: 5vw 0 2vw 0;
    background-color: #F8F6F0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.3958vw;
      margin-bottom: 2.5vw;
    }

    p {
      font-family: "Poppins";
      font-weight: 400;
      color: #000000;
      font-size: 1.0417vw;
      line-height: 1.9;

      &.left-text {
        padding-left: 3vw;
        position: relative;
        margin-bottom: 32px;

        img {
          position: absolute;
          left: 0;
          height: 100%;
          animation: rpop 1.2s infinite;
        }
      }
    }

    h3 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 1.3542vw;
      margin-top: 2.5vw;
    }
  }

  .solution-section {
    padding: 2vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.3958vw;
      margin-bottom: 2.25vw;
      margin-top: 2.25vw;
    }

    h3 {
      font-family: "Poppins";
      font-weight: 400;
      color: #000000;
      font-size: 1.0417vw;
      line-height: 1.9;
      padding-left: 2vw;
      position: relative;
      display: flex;
      align-items: center;

      img {
        position: absolute;
        height: 1.1458vw;
        left: 0;
      }

      &.right-h3 {
        margin-bottom: 1.5vw;
      }
    }
  }

  .gray-section {
    background-color: #445C60;
    padding: 5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #FFFFFF;
      font-size: 2.3958vw;
      text-align: center;
    }

    h3 {
      font-family: "Anton";
      font-weight: 400;
      color: #84A1A5;
      font-size: 1.8750vw;
      text-align: center;
    }
  }

  .service-section {
    background: #F8F6F0;
    padding: 5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.3958vw;
      margin-bottom: 2.5vw;
    }

    p {
      font-family: "Poppins";
      font-weight: 400;
      color: #000000;
      font-size: 20px;
      line-height: 1.8750vw;
      padding-right: 35%;
    }

    h3 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 1.3542vw;
      line-height: 1.8750vw;
      display: flex;
      align-items: center;

      button {
        padding: 0.7vw 3.5vw;
        margin: 0 2vw;
      }
    }
  }

  .direction-section {
    padding: 5vw 0;
    background: white;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.9167vw;
      text-align: center;
      margin-bottom: 5vw;
    }

    .direction-item {
      background: #f9f6f1ff;
      padding: 2.5vw;

      img.bird {
        width: 2.8vw;
        display: block;
        margin: auto auto 1.25vw;
        animation: pop 1.2s infinite;
      }

      h3 {
        font-family: "Anton";
        font-weight: 400;
        color: #000000;
        font-size: 1.5625vw;
        text-align: center;
        margin-bottom: 2.5vw;
      }

      p,
      ul li {
        font-family: "Poppins";
        font-weight: 400;
        color: #000000;
        font-size: 0.9375vw;
        line-height: 1.35;
        text-align: center;
      }

      ul {
        list-style: none;
        padding: 0;
      }

      ul li {
        position: relative;
        text-align: center;

        span {
          padding-left: 1.5vw;
        }

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .last-check {
          top: 25%;
        }
      }

      button {
        padding: 0.7vw 3.5vw;
        display: block;
        margin: 2.5vw auto auto;
      }
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vw 0;
  }

  .slider-section {
    background-color: white;
    position: relative;

    .slider-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .text-section {
      width: 60%;
      padding: 9vw 3vw 9vw 0;

      h2 {
        font-family: "Anton";
        font-weight: 400;
        color: #FF6F69;
        font-size: 2.3958vw;
        font-size: 2.5vw;
        margin-bottom: 2.5vw;
      }

      p {
        font-family: "Poppins";
        font-size: 1.1458vw;
        margin-bottom: 2.5vw;
        padding-right: 4vw;
      }
    }

    .slider-part {
      width: 40%;
      background-image: url("https://s3.eu-central-1.wasabisys.com/balkan-apartments/ttm/6.jpg");
      position: absolute;
      right: 0;
      height: 80%;
      top: 50%;
      transform: translateY(-50%);
      background-size: cover;
      background-repeat: no-repeat;
    }

    .slick-dots {
      bottom: -2.5vw;
      text-align: left;
      margin-left: -5px;
    }

    .slick-dots li button:before {
      font-size: 1vw;
    }

    .route-slide {
      h2 {
        color: black;
      }

      span {
        font-family: "Poppins";
        font-weight: 700;
        color: #000000;
        font-size: 1.2500vw;
      }
    }
  }

  .saying-section {
    padding: 5vw 0;

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #000000;
      font-size: 2.3958vw;
      text-align: center;
      margin-bottom: 2.5vw;
    }

    img {
      display: block;
      margin: auto;
      max-width: 70%;
    }
  }

  .faq-section {
    // background: #F8F6F0;
    padding: 5vw 0;

    .container {
      display: flex;
      justify-content: center;
    }

    h2 {
      font-family: "Anton";
      font-weight: 400;
      color: #FF6F69;
      font-size: 2.3958vw;
      margin-bottom: 2.5vw;
    }

    .question-item {
      h3 {
        font-family: "Anton";
        font-weight: 400;
        color: #000000;
        font-size: 1.7708vw;
        margin-bottom: 1.25vw;
      }

      .check-wrapper {
        display: flex;

        img {
          margin-right: 5px;
          width: 1.4vw;
        }

        span {
          margin-right: 5px;
        }
      }

      p,
      span {
        font-family: "Poppins";
        font-weight: 400;
        color: #000000;
        font-size: 0.9375vw;
        line-height: 1.875vw;
        margin-bottom: 2.5vw;
        display: block;

        &.no-m-p {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .services .services-hero {
    padding: 12vw 0;
    background-image: url("https://s3.eu-central-1.wasabisys.com/tarik/pattern-3.jpeg");
  }

  .services .services-hero .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .services .services-hero h2 {
    font-size: 1rem;
    margin-bottom: 0;
    padding: 0 6vw;
  }

  .services .services-hero h1 {
    font-size: 6.25vw;
    margin-bottom: 12vw;
  }

  .services .tried-section {
    padding: 12vw 0;
  }

  .services .tried-section h2 {
    font-size: 6.25vw;
    padding-bottom: 3vw;
  }

  .services .tried-section p {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .services .tried-section p.left-text {
    padding-left: 10vw;
    margin-bottom: 1rem;
  }

  .services .tried-section h3 {
    font-size: 3.9402vw;
    margin: 3vw 0 6vw;
  }

  .services .solution-section {
    padding: 12vw 0;
  }

  .services .solution-section h2 {
    font-size: 6.25vw;
    height: auto;
  }

  .services .solution-section h3 {
    font-size: 1rem;
    padding-left: 8vw;
    width: 100%;
  }

  .services .solution-section h3 br {
    display: none;
  }

  .services .solution-section h3 img {
    height: 5.1458vw;
  }

  .services .solution-section .col-lg-6 {
    &:first-child {
      margin-bottom: 6vw;
    }

    &:last-child {
      h3 {
        &:first-child {
          margin-bottom: 6vw;
        }
      }
    }
  }

  .services .gray-section h2 {
    font-size: 5.5vw;
  }

  .services .gray-section h3 {
    font-size: 1rem;
  }

  .services .service-section {
    padding: 12vw 0;
  }

  .services .service-section h2 {
    font-size: 6.25vw;
    margin-bottom: 6vw;
  }

  .services .service-section h3 {
    font-size: 5.4348vw;
    line-height: 6.5217vw;
    flex-direction: column;
    align-items: flex-start;

    button {
      width: 60%;
      margin: 3vw 0;
    }
  }

  .services .service-section p {
    font-size: 1rem;
    line-height: 1.25;
    margin-bottom: 6vw;
    padding-right: 0;
  }

  .services .direction-section {
    padding: 12vw 0 0;

    h2 {
      font-size: 6.25vw;
    }

    .direction-item {
      padding: 12vw 6vw 6vw;
      margin-bottom: 12vw;

      img.bird {
        width: 9.8vw;
        display: block;
        animation: pop 1.5s infinite;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        margin: 0;
      }

      h3 {
        font-size: 6.25vw;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  .services .logo-wrapper {
    width: 100%;
    overflow: scroll;

    img {
      padding: 3vw;
      object-fit: contain;

      &:last-child {
        padding-right: 5vw;
      }

      &:first-child {
        padding-left: 5vw;
      }
    }
  }

  .services .slider-section {
    .text-section {
      padding: 12vw 0 64vw;
    }

    .slider-part,
    .text-section {
      width: 100%;
    }

    .slider-part {
      position: absolute;
      height: 50vw;
      bottom: 0;
      top: auto;
      transform: none;

      .slider {
        top: 0;
        transform: none;
        display: block;
        margin: auto;
        width: 71.6033vw;
        height: auto;

        .home-slide {
          padding: 15vw 5vw 5vw;

          p {
            font-size: 1rem;
          }
        }
      }
    }

    .text-section {
      h2 {
        font-size: 6.2500vw;
      }

      p {
        font-size: 1rem;
      }
    }

    .slider-part .slider {
      .slick-next,
      .slick-prev {
        height: 5vw;
        width: 4vw;
      }

      .home-slide img {
        width: 8.4375vw;
        height: 8.4896vw;
        top: 30%;
      }
    }

    .slick-dots li button:before {
      font-size: 3vw;
    }

    .route-slide span {
      font-size: 1rem;
    }
  }

  .services .slider-section .slick-dots {
    bottom: -9vw;
  }

  .services .saying-section {
    padding: 12vw 0;

    h2 {
      font-size: 6.25vw;
      margin-bottom: 12vw;
    }

    .col-lg-4 {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  .services .faq-section {
    padding: 12vw 0;

    h2 {
      font-size: 6.25vw;
      margin-bottom: 12vw;
    }

    .question-item {
      h3,
      p {
        font-size: 1rem;
        line-height: 1.5;
      }

      .check-wrapper {
        display: block;
        margin: 3vw 0;
        span {
          font-size: 1rem;
          display: block;
        }

        img {
          width: 4.4vw;
        }
      }
    }
  }

  .services .citation-section button.slick-arrow {
      display: none !important;
    }
}
