.videos {
  @media (max-width: 700px) {
    .player-wrapper {
      position: relative;
      div{
        video{
          height: 250px !important;
          background: #000;
        }
      }
    }
    .vpp-group-2{
      height: 350px;
    }
    .vpp-text-3 {
      overflow-y: auto;
      height: 284px;
  }
    .vpp-layer-1{
      height: 100vh !important;
      top:0;
    }
  }
  .player-wrapper {
    position: relative;
    div{
      video{
        height: 350px;
        background: #000;
      }
    }
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;

    left: 10px;

  }
  .price{
    top: 4px;
    position: relative;
  }
  .fake-buy{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 28px;
    position: relative;
    padding: 0px 12px;
    display: block;
    height: 48px;
    line-height: 30px;
    color: white;
    font-weight: bold;
    box-shadow: rgb(255 255 255 / 25%) 0px 1px 0px inset;
    text-shadow: rgb(0 0 0 / 25%) 0px -1px 0px;
    border-radius: 4px;
    width: 179px;
    background: #000;
  }
  .react-player__preview{
  }
  background: #F9F6F1;

  .videos-header {
    padding-top: 100px;
    h1 {
      font-family: Anton, sans-serif;
      font-size: 77px;
    }

    h2 {
      font-family: 'Poppins';
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 30px;

      .text-style {
        color: #ff6f69;
        font-weight: normal;
      }

      .fw400 {
        font-weight: 400;
      }
    }

    h4 {
      font-family: 'Poppins';
      font-size: 1.25vw;
      line-height: 2.0313vw;
      font-weight: 400;
    }
    .promo-thumb-holder{
      height: fit-content !important;
    }
    .play-button {
      position: absolute;
      width: 20%;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    img {
      width: 100%;
    }
  }

  .videos-container {
    padding-top: 5vw;
    h2 {
      font-family: Anton, sans-serif;
      font-size: 1.8229vw;
      text-align: center;
      margin-bottom: 2vw;
    }
    .video-wrapper {
      padding: 0 1.5vw;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .video-item {
      background: #DADBDC;
      cursor: pointer;
      width: 23%;
      margin-right: 1%;
      margin-bottom: 1%;
      display: flex;
      img{
        width: 100%;
      }
    }
  }
}

.modal {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
}

.vpp-layer-1 {
  height: 80vh;
  position: absolute;
  top: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 621px;
  width: 100%;
  background: #F9F6F1;
  background-size: contain;
  display: flex;
  flex-direction: column;
}

.vpp-group-2 {
  max-height: 350px;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background-size: contain;
  overflow: hidden;
}

.vpp-group-3 {
  height: 34px;
  position: absolute;
  right: 1.2500vw;
  top: 1.8750vw;
  width: 34px;
  background: url('https://s3.eu-central-1.wasabisys.com/tarik/vpp/path.png') no-repeat;
  background-size: contain;
}

.vpp-group-4 {
  left: 7px;
  position: absolute;
  top: 8px;
  width: 18px;
  height: 18px;
}

.vpp-group-5 {
  .buy-holder{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  position: relative;
  width: 100%;
  background-size: contain;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  .StripeCheckout{
    span{
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 28px !important;
      position: relative;
      padding: 0px 12px;
      display: block;
      height: 48px !important;
      line-height: 46px !important;
      color: white;
      font-weight: bold;
      box-shadow: none !important;
      text-shadow: rgb(0 0 0 / 25%) 0px -1px 0px;
      border-radius: 4px;
      width: 179px;
      background: #000;
      background-image: none !important;
    }
    font-size: 28px !important;
    width: 179px;
    height: 48px;
    background: #000 !important;
  }

}

.vpp-play {
  left: 3.0208vw;
  position: absolute;
  top: 0;
  color: #ffffff;
  font-size: 0.9541vw;
  font-weight: 500;
  line-height: 2.2396vw;
  text-align: right;
}

.vpp-text {
  position: relative;
  color: #000000;
  font-family: Anton, sans-serif;
  font-size: 36px;
  line-height: 1;
  margin-top: 20px;
  .date{
    color: #ff6f69;
    font-size: 24px;
  }
}

.vpp-text-2 {
  font-weight: 500;
}

.vpp-text-3 {
  width: 100%;
  font-size: 20px;
}

.vpp-text-4 {
  font-weight: bold;
}

.vpp-group-6 {
  width: 90%;
}

.vpp-fw700 {
  font-weight: bold;
  font-size:28px;
}

.vpp-layer-1 {
  margin-bottom: 3vw;
}

@media (max-width: 991px) {
  .videos {

    .video-item {
      width: 49% !important;
      margin-right: 1%;
      margin-bottom: 1%;
      display: flex;
      img{
        width: 100%;
      }
    }
    .videos-header {
      padding-top: 50px;
      h1 {
        font-size: 6.25vw;
      }
      h2 {
        font-size: 1rem;
      }
      h4 {
        font-size: 1rem;
        line-height: 1.25;
      }
    }

    .videos-container {

      h2 {
        padding-left: 6vw;
        margin-bottom: 6vw;
        font-size: 6.25vw;
      }
    }
  }
  .videos .videos-container .video-item {
    height: 24.7619vw;
  }
}
.loader-svg{
  svg {
    width: 100%;
    max-width: 10rem;
    animation: rotate 3.6s linear infinite;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    margin-top: 20vh;
    margin-bottom: 20vh;
  }

  circle {
    fill: none;
    stroke: #FF6F69;
    stroke-width: 8px;
    stroke-dasharray: 300;
    animation: outline 2s cubic-bezier(0.77,0,0.18,1) infinite;
  }

  @keyframes outline {
    0% {
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dashoffset: 300;
    }
    100% {
      stroke-dashoffset: 600;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(-1turn);
    }
  }
}