footer {
  position: relative;
  padding: 20px 0;
  height: 233px;

  .footer-wrapper {
    display: flex;
    flex-wrap: wrap;

    form {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      input {
        display: block;
        text-align: center;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid gray;
        outline: none;
        font-family: "Poppins";
        font-weight: 500;
        color: #000000;
        font-size: 16px;
        width: 50%;
        margin: auto auto 20px;

        &::placeholder {
          font-family: "Poppins";
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          font-size: 16px;
        }
      }

      textarea {
        display: block;
        text-align: center;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid gray;
        resize: none;
        outline: none;
        font-family: "Poppins";
        font-weight: 500;
        color: #000000;
        font-size:20px;
        width: 50%;
        margin: auto;
        height: 2.1vw;
        margin-bottom: 1.25vw;

        &::placeholder {
          font-family: "Poppins";
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          font-size: 20px;
        }
      }

      h2 {
        font-family: "Poppins";
        font-weight: 700;
        color: #000000;
        font-size: 1.5625vw;
        text-align: center;
        margin-bottom: 2.5vw;
      }
    }

    img {
      width: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      object-fit: cover;
    }
  }

  button {
    padding: 10px !important;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    &.footer-button {
      font-family: "Anton";
      font-weight: 400;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffffff;
      font-size: 16px;
      text-align: center;
      padding: 0.7vw 3.5vw;
      background: #FF6F69;
      color: white;
      border: none;
      display: block;
      margin: auto;
    }
  }
}


@media (max-width: 991px) {
  footer {
    padding: 0;
    .container {
      padding: 0;
    }
    .footer-wrapper {
      flex-wrap: nowrap;
      flex-direction: column;

      form {
        width: 100%;
        padding: 12vw 0;

        h2 {
          font-size: 6.25vw;
        }

        input,
        textarea {
          font-size: 0.95rem;
          width: 70%;

          &::placeholder {
            font-size: 0.95rem;
          }
        }

        textarea {
          height: 7.1vw;
          margin-bottom: 6vw;
        }
      }

      img {
        position: relative;
        width: 100%;
      }
    }

    button.footer-button {
      line-height: 3;
      font-size: 20px;
      width: 70%;
    }
  }
}
