.successfull-payment{
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
    h1{
        text-align: center;
        font-family: "Anton";
        font-weight: 400;
        color: #000000;
        font-size: 2.9167vw;
        text-align: center;
        margin-bottom: 100px;
    }
    svg{
        font-size: 10vw;
        color: #FF6F69;
        margin-bottom: 250px;

    }
}